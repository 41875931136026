/*@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
*/
/*
*.woff2 fonts are smaller and therefor download faster and result in faster loading times.  Modern browsers support .woff2 format.
Previous versions have used true type fonts; however, the font formats can be significantly smaller.  Tinos true type font is 
455KB and the woff2 version is 28KB.  More typical reductions are on the order of 3:1 or 4:1, with the typical resulting font being
in the range of 15KB to 30KB.  Most modern browsers only download a font when it's use is detected.  IE 11 being an exception.
*/
@font-face {
  font-family: "Tinos";
  src: local("Tinos-Regular"),
    url("./fonts/tinos-v24-latin-regular.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "RieslingRegular";
  src: local("RieslingRegular"),
    url("./fonts/Riesling.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "TheGirlNextDoor";
  src: local("TheGirlNextDoor"),
    url("./fonts/TheGirlNextDoor-400.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Sacramento";
  src: local("Sacramento"),
    url("./fonts/sacramento-v13-latin-regular.woff2") format("woff2");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: "Tinos";
  margin: 0;
  /*color: #808080;*/
}

.navbar {
  padding: 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.5rem;
  /*border-bottom: 1px solid #f2f2f2;*/
}
.navbar .menu {
  align-content : center;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
  display: flex;
  gap: 0.5rem;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  /*margin-left: auto;*/
}
.navbar a {
  margin-left: 8px;
  text-decoration: none;
  padding: 4px;
  color: #A0A0A0;
}
.navbar .menu a.active {
  color: #1f1f1f;
}
.navbar a:hover {
  color: #000000;
}
.content {
  /*max-width: 1200px; */
  /*margin: 40px auto; */
  margin: 0 0 0 0;
  padding: 0px;
}

h1 {
  font-size: 2.5vw;
  color: #ffffff;
  text-align: center;
  padding-top: 1.0rem;
  padding-bottom: 1.5rem;
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 24px;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #1f1f1f /* #373a47; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


